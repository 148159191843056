import { clsx } from 'clsx';
import Partials from 'components/partials/Partials';
import type { PartialWidgetContext } from 'types/cms';
import type { PropsWithClassName } from 'types/react-props';
import { getKey } from 'utils/idGenerator';
import styles from './group-grid-default.module.scss';

export type GroupGridProps = PropsWithClassName<{
  readonly children: Array<PartialWidgetContext>;
}>;

const GroupGridDefault = ({ context }: { context: GroupGridProps }) => (
  <div className={clsx(styles.grid, context.className)}>
    {context.children.map((widget: PartialWidgetContext) => (
      <Partials key={getKey(widget.id, widget)} context={widget} />
    ))}
  </div>
);

export default GroupGridDefault;
